<template>
  <div>
    <el-form :inline="true" :model="form" :ref="form" label-width="100px">
      <el-row style="background-color: #FFFFFF;padding-top: 18px;">
        <el-col style="display: flex;align-items: center;justify-content: flex-start;">
          <el-form-item label="打卡范围">
            <el-input-number :controls="false" v-model="form.value" /> 米
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" @click="submitForm">确定</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form :inline="true" :model="timeform" :ref="timeform" label-width="100px">
      <el-row style="background-color: #FFFFFF;padding-top: 18px;">
        <el-col style="display: flex;align-items: center;justify-content: flex-start;">
            <el-form-item label="正常打卡时间：上课前" prop="signStart" label-width="180px">
              <el-input v-model="timeform.signStart" style="width:50px"></el-input>
              分钟
            </el-form-item>
            <el-form-item label="迟到打卡时间：上课后" prop="late" label-width="200px">
              <el-input v-model="timeform.late" style="width:50px"></el-input>
              分钟内
            </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" @click="submittimeform">确定</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  import { addSetting, getSetting, Edittime } from '@/api/system/setting.js'
  import { getConfig } from '@/api/user.js'
  export default {
    name: 'Sidebar',
    data() {
      return {
        settingList: {},
        form: {},
        timeform: {}
      }
    },
    created() {
      this.gettimeData()
      this.getList()
    },
    methods: {
      // 获取打卡时间范围
      gettimeData() {
        getConfig().then(res => {
          this.timeform = res.data.data
        })
      },
      // 修改打卡时间范围
      submittimeform() {
        Edittime(this.timeform).then(res => {
          if (res.data.code === '0') {
            this.$notify({
              title: '修改成功',
              type: 'success'
            })
          } else {
             this.$notify.info({
              message: res.data.msg
            })
          }
        })
      },
      // 查找打卡范围
      getList() {
        getSetting().then(res => {
          this.form.value = res.data.data.value
          this.form.id = res.data.data.id
        })
      },
      // 添加打卡范围
      submitForm() {
        console.log(this.form)
        addSetting(this.form).then(res => {
          //  this.msgSuccess('修改打卡范围成功')
          this.$notify({
            title: '成功',
            message: '修改打卡范围为 ' + this.form.value + '米',
            type: 'success'
          })
        })
      }
    }
  }
</script>

<style>
</style>
