import request from '@/utils/request'
// 系统管理 --- 系统设置
// 系统设置下目前只有设置打卡范围这一功能  ---2021-11-2
// 保存打卡范围
export function addSetting (data) {
  return request({
    url: '/bh/admin/config/submitSave',
    method: 'post',
    data: data
  })
}
// 查找打卡范围
export function getSetting () {
  return request({
    url: '/bh/admin/config/detail/1',
    method: 'get'
  })
}
// 修改打卡时间限制范围
export function Edittime (data) {
  return request({
    url: '/bh/admin/config/update',
    method: 'post',
    data: data
  })
}
